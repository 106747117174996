@import url('https://fonts.googleapis.com/css?family=Press+Start+2P');
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700;800&display=swap');

@import './Animations.scss';

:root {
  --white: #ffffff;
  --grey: #c4c4c4;
  --lightGrey: efefef;
  --black: #000000;
  --txtColor: #5c5c5c;
  --transparentBlack: #6b6b6b24;
  --transparentWhite: #fffffff7;
  --borderColor: #dfdfdf; // #151515;
  --secBlack: #222222;
  --red: #ff3e48;
  --secRed: #9f2a30;
  --txtGreen: #14a800; //#99ff8a;//#ccffc4;
  --lightGreen: #39ff14; // #99ff8a;
  --green:#39ff14; // #36B34B;
  --secGreen: #24b30e;
  --yellow: #ffb73d;
  --secYellow: #f2ea12;
  --purple: #b160fb;
  --secPurple: #9654d3;
  --blue: #2465e1;
  --secBlue: #00f;
  --teal: #14ffbf;
  --secTeal: #0eb385;
  // --secBlue: #161668;
  --info: var(--blue);
  --primaryColor: var(--green);
  --primaryLightColor: var(--lightGreen);
  --primaryDarkColor: var(--secGreen);
  --secondaryColor: var(--teal);
  --secondaryDarkColor: var(--secTeal);
  --accentColor: var(--yellow); //#FFB73D;
  --secAccentColor: var(--secYellow); //#F2EA12;
  --titleColor: var(--secBlack);
  // --bgColor: var(--black);
  --bgColor: var(--lightGrey);
  --landsBg: #f9f8ff;
  --defBorderRadius: 5px;

  --font: 'Press Start 2P', cursive;
  --titleFont: 'Orbitron', sans-serif;
  --defBoxShadow: 0px 1px 3px 3px var(--bgColor);
  --defRadius: 5px;

  --pagePading: 20px 30px; // 0px 10vw;
  --fullPagePading: 200px 10vw;
  --maxPageHeight: 1560px;
  --maxPageWidth: 1560px;
  --fontSize: 1em;

  --mainNavHeight: 50px;
  --sideNavWidth: 90px;
  // --gridAspectRatio: 	16 /9;
  --gridAspectRatio: 	233 / 144	; //http://oliver.prygotzki.de/privat/techtalk/aspectratio.html
}

@mixin defTxt {
  font-family: var(--font);
  color: var(--black);
  font-weight: normal;
  line-height: 1.2em;
}
@mixin headerTxt {
  font-family: var(--titleFont);
  color: var(--black);
  font-weight: normal;
  font-size: 1.2em;
  line-height: 1.2em;
}

@mixin pointerItem {
  cursor: pointer;
  outline: none;
}

@mixin pageWrapper {
  display: flex;
  flex-flow: column;

  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 30px;
  grid-gap: 30px;

  position: relative;
  box-sizing: border-box;

  .page-header-wrapper {
    display: grid;
    grid-template-columns: 50px auto;
    grid-template-rows: 50px;
    grid-gap: 30px;

    justify-content: start;
    align-items: center;
  }
}

h1,
h2,
h3,
h4,
h6,
p,
b,
span {
  @include defTxt();
  margin: 0px;
  padding: 0px;
}

a,
ul {
  @include defTxt();
  color: var(--primaryColor);
  text-decoration: none;
}

a {
  @include pointerItem();
  &:hover {
    color: var(--accentColor);
  }
}

div{
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
-moz-box-sizing: border-box;    /* Firefox, other Gecko */
box-sizing: border-box;         /* Opera/IE 8+ */
}

.pl-10 {
  padding-left: 10px;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}
.mb-200 {
  margin-bottom: 200px !important;
}

body {
  margin: 0px;
  padding: 0px;
  font-family: var(--font);
}

.title-txt {
  @include defTxt();
  color: var(--black);
  font-size: 1.2em;
}
.def-txt {
  @include defTxt();
  color: var(--txtColor);
  font-size: 0.8em;
  line-height: 1.6em;

}
.success-txt {
  @include defTxt();
  // color: var(--secGreen) !important;
  color: var(--txtGreen) !important;
}

.info-txt {
  @include defTxt();
  color: var(--info);
}

.error-txt {
  @include defTxt();
  color: var(--red);
}
.color-primary, .color-green {
  color: var(--txtGreen) !important;
}
.color-grey {
  color: var(--grey);
}
.color-red {
  color: var(--red);
}

.success-txt,
.info-txt,
.error-txt {
  font-size: 0.8em;
}

.def-link {
  @include defTxt();
  @include pointerItem();
  color: var(--accentColor);

  &:hover {
    color: var(--accentColor);
  }
}

// BUTTON START ---------------------------/
.pill {
  @include defTxt();
  @include pointerItem();
  font-size: 0.8em;
  font-weight: normal;
  border: 2px solid var(--borderColor);
  border-radius: 20px;
  padding: 10px;
  width: max-content;

  &:hover,
  &.active,
  &:active {
    background-color: var(--white);
    color: var(--black);
    border: 2px solid var(--primaryDarkColor);
  }

  &.primary,
  &.success {
    background-color: var(--green);
    border: 2px solid var(--secGreen);
    &:hover {
      background-color: var(--green) !important;
    }
  }

  &.outline {
    border: 2px solid var(--secBlack);
    background-color: var(--bgColor);
    &:hover {
      background-color: var(--bgColor) !important;
    }
  }
  &.danger {
    border: 2px solid var(--secRed);
    background-color: var(--red);
    &:hover {
      color: var(--white);
      // background-color: var(--secRed) !important;
    }
  }
}

.transparent-btn,
.circle-btn,
.primary-btn,
.accent-btn,
.outline-btn,
.black-btn,
.tab-btn,
.box-btn {
  @include defTxt();
  @include pointerItem();
  font-size: 0.8em;

  padding: 10px;
  border-radius: var(--defRadius);
  border: 2px solid var(--borderColor);
  text-align: center;
  display: flex;
  grid-gap: 5px;
  align-items: center;
  justify-content: center;

  & > svg {
    font-size: inherit;
  }

  &:hover,
  &.active,
  &:active {
    // background-color: var(--accentColor);
    background-color: var(--white);
    color: var(--black);
    font-weight: bold;
    border: 2px solid var(--primaryColor);
  }
}

.box-btn {
  height: 65px;
  width: 70px;
  min-width: 50px;
  flex-flow: column;
  grid-gap: 8px;
  padding: 5px;
  box-sizing: border-box;
  background-color: var(--landsBg);
  svg {
    font-size: 2.5em;
    color: var(--txtColor);
  }

  p {
    color: var(--black);
    font-size: 0.6em;
    font-family: var(--font);
  }

  &.active {
    color: var(--black);
    box-shadow: 0px 2px 10px 1px var(--primaryDarkColor);
    background-color: var(--primaryDarkColor) !important;

    svg,
    p {
      color: var(--black) !important;
    }
  }

  &:hover {
    box-shadow: 0px 2px 10px 3px var(--primaryColor);
    background-color: var(--bgColor);
    border: 2px solid var(--black);
  }
}

.primary-btn {
  color: var(--white);
  background-color: var(--primaryColor);
  border: 2px solid var(--primaryDarkColor) !important;
  &:hover,
  &:active {
    color: var(--white);
    box-shadow: 0px 2px 10px 3px var(--borderColor);
    background-color: var(--primaryDarkColor) !important;
  }
}

.transparent-btn{
  background-color: transparent;
  border: none !important;
}
.tab-btn,
.outline-btn {
  color: var(--black);
  border: 2px solid var(--secBlack) !important;
  background-color: var(--bgColor);
  &:hover,
  &.active,
  &:active {
    color: var(--white);
    background-color: var(--black);
    box-shadow: 0px 2px 10px 3px var(--borderColor) !important;
  }
}

.tab-btn {
  border-radius: 20px;
}

.accent-btn {
  color: var(--black);
  background-color: var(--accentColor);

  &:hover,
  &:active {
    box-shadow: 0px 2px 10px 3px var(--borderColor);
    background-color: var(--secAccentColor);
    color: var(--black);
    font-weight: bold;
  }
}

.black-btn {
  color: var(--grey);
  background-color: var(--secBlack);
}

.circle-btn {
  width: 50px;
  height: 50px;
  display: flex;
  border-radius: 50%;
  place-content: center;

  & > svg {
    font-size: 2em;
  }
}

button:disabled,
button[disabled] {
  cursor: wait !important;
  background-color: var(--grey) !important;
  color: var(--txtColor) !important;
  outline: none;
  border: 2px solid var(--black) !important;
  padding: 10px;
  &:hover {
    cursor: wait !important;
    background-color: var(--grey) !important;
    color: var(--txtColor) !important;
    border: 2px solid var(--black);
  }
}

// BUTTON END ---------------------------/

// MAIN PAGE LOADER START ------//
.main-page-loader-wrapper {
  display: grid;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  min-width:100%;
  background-color: var(--green);
  box-sizing: border-box;
  position: relative;
  cursor: grab;
  overflow: auto;
  padding: 10vh;
}
// MAIN PAGE LOADER END ------//

// ABOUT PAGE START ------//

.about-page-wrapper {
  @include pageWrapper();

  .about-txt-wrapper {
    max-width: 1000px;

    p {
      font-family: var(--font) !important;
    }
  }
}

// ABOUT PAGE END ------//

// HOME PAGE START ------//

.land-body-wrapper {
  display: grid;
  position: relative;
  grid-template-columns: repeat(162, 10px);
  grid-template-rows: repeat(100, 10px);
  // border: 3px solid var(--black);
  box-sizing: border-box;
  // border-top: .5px solid var(--grey);
  // border-left: .5px solid var(--grey);
  margin: 0px auto;

  .land-info-popup {
    @include pointerItem();
    position: fixed;
    top: 345px;
    left: 75px;
    width: 200px;
    min-height: 100px;
    display: none;
    flex-flow: column;
    border-radius: 5px;
    background-color: var(--white);
    border: 2px solid var(--borderColor);
    padding: 0px;
    grid-gap: 15px;
    z-index: 1000;

    .land-info-popup-header {
      text-align: right;
      width: 100%;
      min-height: 100px;
      position: relative;

      background: radial-gradient(var(--black), var(--transparentBlack));

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0px;
        top: 0px;
      }

      .count-wrapper {
        position: absolute;
        right: 10px;
        top: 10px;
        color: var(--white);
      }
    }

    .land-info-popup-body {
      display: flex;
      box-sizing: border-box;
      grid-gap: 10px;
      flex-flow: column;
      padding: 10px;

      p {
        font-size: 0.5em;

        &b {
          font-weight: bold;
          font-size: 0.7em;
        }
      }
    }
  }

  .land-item {
    @include pointerItem();
    display: block;
    height: 100%;
    width: 100%;
    // border-right: .5px solid var(--grey);
    // border-bottom: .5px solid var(--grey);
    background-color: var(--white);

    &.not-available {
      background-color: var(--primaryColor);
    }

    &.active {
      background-color: var(--accentColor);
    }

    &:hover {
      background-color: var(--accentColor);
      -webkit-animation: shadow-drop-2-center 0.4s
        cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation: shadow-drop-2-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        both;
    }
  }
}

.account-display {
  @include pointerItem();
  @include defTxt();
  position: relative;
  height: 35px;
  width: max-content;
  display: grid;
  grid-template-columns: 35px auto;
  grid-gap: 10px;
  align-items: center;
  background-color: var(--accentColor);
  border-radius: 20px;

  font-size: 0.8em;
  font-weight: bold;
  align-items: center;
  box-sizing: border-box;
  // grid-template-rows: 35px;
  border: 2px solid var(--black);
  box-sizing: content-box;

  justify-content: flex-end;

  & > div > div {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border: 3px solid var(--accentColor);
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    box-sizing: border-box;
  }
  .account-menu-wrapper {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    height: auto;
    min-height: 50px;
    background-color: var(--transparentWhite);
    border: 2px solid var(--bgColor);
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 10;
    display: flex;
    grid-gap: 10px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    overflow: hidden;

    &.active {
      opacity: 1;
      z-index: 1;
    }
  }
}

// HOME PAGE END   ------//

// HOME PAGE END   ------//

// MODAL SECTION START   ------//
.ReactModal__Overlay {
  z-index: 10;
}
.ReactModal__Content {
  max-height: 80vh !important;
  padding: 0px !important;
}

.modal-body-wrapper {
  max-width: 500px;
}

.lg-modal-body-wrapper{
  // height: 100vh;
  // width: 100vw;
  overflow-y: auto;
}
.lg-modal-body-wrapper,
.modal-body-wrapper {
  display: flex;
  flex-flow: column;
  width: 100%;
  position: relative;
  padding: 20px 30px;
  min-height: 250px;
  min-width: 500px;

  background-color: var(--white);
  border: 3px solid var(--black);
  border-radius: var(--defRadius);
  box-sizing: border-box;

  .modal-header,
  .modal-body {
    width: 100%;
    min-width: 300px;
    z-index: 100;
  }

  .modal-header {
    display: grid;
    grid-template-columns: auto 50px;
    grid-gap: 20px;
    box-sizing: border-box;
    align-items: center;
  }
  .modal-title,
  .modal-sub-title {
    @include defTxt();
    font-size: 1.5em;
    font-family: var(--titleFont);
    font-weight: bold;
  }
  .modal-sub-title {
    font-size: 1em;
  }

  .modal-body {
    display: flex;
    box-sizing: border-box;
    flex-flow: column;
    width: 100%;

    .modal-logo-wrapper {
      display: grid;

      // place-content: center;
      img {
        height: 100px;
      }
    }

    .modal-success-wrapper {
      display: flex;
      height: 150px;
      align-items: center;
      justify-content: center;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }


    .mint-modal-error-wrapper{
      display: flex;
      background-color: #f3f3f3;
      place-content: center;
      height: 300px;
      box-sizing: border-box;
      flex-wrap: wrap;

      img{
        width: auto;
        max-width: 90%;
        height: inherit;
        // border-radius: var(--defBorderRadius);
      }
    }

    .mint-modal-loader-wrapper{
      display: grid;
      grid-template-columns: repeat(2,50%);
      grid-template-rows: 200px;

      grid-gap: 30px;
      box-sizing: border-box;

          place-content: center;

      .logo-wrapper{
        img{
          max-height: 200px;;
          max-width: 200px;;
        }
      }

      .logo-wrapper,
      .loader-wrapper{

        display: flex;
        place-content: center;
        box-sizing: border-box;
      }  
      .loader-wrapper{
        padding: 70px 50px;
      }  

    }

  }

  .modal-btn-wrapper {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 10px));
    grid-gap: 20px;
    box-sizing: border-box;

    a,
    button {
      height: 100%;
      max-height: 40px;
      width: 100%;
    }
  }




    .modal-collections-wrapper {
      display: flex;
      flex-flow: column;
      grid-gap: 50px;
      box-sizing: border-box;

      .collections-info-wrapper{
        display: grid;
        grid-template-columns: 400px auto;
        align-items: flex-end;
        box-sizing: border-box;
        grid-gap: 80px;

        .collections-details-wrapper{
          display: flex;
          flex-flow: column;
          grid-gap: 10px;
        }
        .collections-img-wrapper{
          display: flex;
          box-sizing: border-box;
          min-height: 200px;
          img{
            height: auto;
            width: 100%;
            max-width: 500px;
          }
        }
      }

      .collections-actions-wrapper {
         display: flex;
        flex-flow: row;
        grid-gap: 20px;
        box-sizing: border-box;
        // justify-content: flex-end;

      }
      .collections-parcels-wrapper {
        display: flex;
        flex-flow: column;
        grid-gap: 20px;
        box-sizing: border-box;

        .parcels-renderer-wrapper{
          display: grid;
          box-sizing: border-box;
          grid-gap: 5px;
          grid-template-columns: repeat(auto-fill, 100px);
          grid-template-rows: repeat(auto-fill, 100px);

          .parcel-render-item{
              display: flex;
              box-sizing: border-box;
              align-items: flex-start;
              justify-content: center;
              
              flex-flow: column;
              grid-gap: 5px;
              height: 100px;
              width: 100%;
              border-radius: 2px;
              border: 1px solid var(--black);
              padding: 10px;
              h3{
                font-size: .6em;
                color: var(--black);
              }
              p{
                // margin: auto;
                font-size: .5em;
              }
            }
        }
      } 

    }

}

// MODAL SECTION END   ------//

// DRAG & DROP SECTION START   ------//
.image-dropzone {
  font-size: 0.6em;
  padding: 30px;
  border: 3px dotted var(--grey);
  border-radius: var(--defRadius);
}

.modal-preview-img {
  min-height: 200px;
  max-height: 255px;
  object-fit: cover;
}
// MODAL SECTION END   ------//



// FORM START ---------------------------//

form {

 
  .form-item {
    display: flex;
    flex-flow: column;
    max-width: 500px;
    grid-gap: 10px;
    label{
     @include headerTxt();
    }

    input,
    textarea{
    @include defTxt();
    border-radius: var(--defRadius);
    padding: 10px;
    border: 2px solid var(--black);

    &:active,
    &:target,
    &:focus{
      border: 3px solid var(--primaryColor);
    }
    }
  }

  .form-input-footer {
    display: flex;
    flex-flow: column;
    width: 100%;
  }

   .submit-btn{
    min-width: 200px;
  }

  .validation-error-wrapper,
    .validation-error-wrapper>b,
  .form-error  {
    color: var(--red);
    font-size: .9em;
  }
}

// FORM END ---------------------------//



// LOADER START  ------//
.cube-loader {
    height: 5px;
    width: 5px;
    color: var(--black);
    margin-left: 30px;
    margin-top: 20px;
    box-shadow: -10px -10px 0 5px, -10px -10px 0 5px, -10px -10px 0 5px,
      -10px -10px 0 5px;
    animation: loader-38 6s infinite;
  }

  @keyframes loader-38 {
    0% {
      box-shadow: -10px -10px 0 5px, -10px -10px 0 5px, -10px -10px 0 5px,
        -10px -10px 0 5px;
    }

    8.33% {
      box-shadow: -10px -10px 0 5px, 10px -10px 0 5px, 10px -10px 0 5px,
        10px -10px 0 5px;
    }

    16.66% {
      box-shadow: -10px -10px 0 5px, 10px -10px 0 5px, 10px 10px 0 5px,
        10px 10px 0 5px;
    }

    24.99% {
      box-shadow: -10px -10px 0 5px, 10px -10px 0 5px, 10px 10px 0 5px,
        -10px 10px 0 5px;
    }

    33.32% {
      box-shadow: -10px -10px 0 5px, 10px -10px 0 5px, 10px 10px 0 5px,
        -10px -10px 0 5px;
    }

    41.65% {
      box-shadow: 10px -10px 0 5px, 10px -10px 0 5px, 10px 10px 0 5px,
        10px -10px 0 5px;
    }

    49.98% {
      box-shadow: 10px 10px 0 5px, 10px 10px 0 5px, 10px 10px 0 5px,
        10px 10px 0 5px;
    }

    58.31% {
      box-shadow: -10px 10px 0 5px, -10px 10px 0 5px, 10px 10px 0 5px,
        -10px 10px 0 5px;
    }

    66.64% {
      box-shadow: -10px -10px 0 5px, -10px -10px 0 5px, 10px 10px 0 5px,
        -10px 10px 0 5px;
    }

    74.97% {
      box-shadow: -10px -10px 0 5px, 10px -10px 0 5px, 10px 10px 0 5px,
        -10px 10px 0 5px;
    }

    83.3% {
      box-shadow: -10px -10px 0 5px, 10px 10px 0 5px, 10px 10px 0 5px,
        -10px 10px 0 5px;
    }

    91.63% {
      box-shadow: -10px -10px 0 5px, -10px 10px 0 5px, -10px 10px 0 5px,
        -10px 10px 0 5px;
    }

    100% {
      box-shadow: -10px -10px 0 5px, -10px -10px 0 5px, -10px -10px 0 5px,
        -10px -10px 0 5px;
    }
  }
// LOADER END   ------//

.display-none {
  // visibility: visible !important;
  display: none;
}

.sm-hidden {
  // visibility: visible !important;
  display: inline-flex !important;
}

.lg-hidden {
  // visibility: hidden !important;
  display: none !important;
}

@media screen and (min-width: 981px) and (max-width: 1280px) {
}

@media screen and (min-width: 768px) and (max-width: 980px) {
}

@media only screen and (max-width: 767px) {
  /* phones */

  :root {
    --pagePading: 5vw;
    --fullPagePading: 100px 5vw;
    --defFontSize: 1em;
  }

  .sm-hidden {
    // visibility: hidden !important;
    display: none !important;
  }

  .lg-hidden {
    // visibility: visible !important;
    display: inline-flex !important;
  }

  .side-nav-title{
    font-size: 1em;
  }

  .title-txt  {
    font-size: 1em !important;
  }

  .def-txt,
  .pill,
  .form-error {
    font-size: .7em !important;
    line-height: 1.2em !important;
  }
.page-wrapper{
  grid-gap: 0px !important;
}
  .ReactModal__Content{
    max-height: 100vh !important;
    max-width: 100vw !important;

    .modal-body-wrapper {
      padding: var(--pagePading);
      min-width: 100% !important;
      max-width: 100vw !important;
    }
  }

  .connection-list-item{
    grid-template-columns: 100%;
    grid-gap: 10px;
    max-width: 100% !important;
  }

  .mint-modal-loader-wrapper {
    .logo-wrapper{
      img{

    height: 150px !important;
    width: 150px !important;

        max-width: 200px !important;
        max-height: 200px !important;
      }
    }
  }
}
