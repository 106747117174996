@import './App.scss';
@import './Animations.scss';

// BASE PAGE START ------//
.base-page-wrapper {
  position: relative;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  overflow: hidden;

  .side-nav,
  .map-menu-nav,
  .parcel-menu-nav {
    background-color: var(--white);

    position: fixed;
    top: 0px; //var(--mainNavHeight);
    z-index: 2;
    height: 100vh; //calc(100vh - var(--mainNavHeight));
    box-shadow: 0px 0px 0px 2px var(--bgColor);
    box-sizing: border-box;
  }

  .side-nav {
    padding: 20px 10px;
    left: 0;
    background-color: var(--white);

    width: var(--sideNavWidth);

    display: flex;
    flex-flow: column;
    grid-gap: 30px;
  }
  @mixin SideNavListItem {
    display: grid;
    align-items: center;
    box-sizing: border-box;
    grid-gap: 20px;
    grid-template-rows: 50px;
    padding: 0px 10px;

    grid-template-columns: auto 40px;

    .map-menu-close-wrapper {
      display: flex;
      justify-content: flex-end;
      font-size: 2em;

      svg {
        @include pointerItem();
        color: var(--txtColor);

        &:hover {
          color: var(--primaryColor);
        }
      }
    }
  }

  .map-menu-nav,
  .parcel-menu-nav {
    padding: 0px;
    display: flex;
    flex-flow: column;
    // grid-gap: 30px;
    width: 300px;
    background-color: var(--transparentWhite);

    .side-nav-list-item {
      @include SideNavListItem();
    }

    .side-nav-header {
      @include SideNavListItem();
      border-bottom: 1px solid var(--bgColor);
    }

    .side-nav-left-input-wrapper {
      @include SideNavListItem();
      grid-template-columns: 30px auto;
    }

    .side-nav-sub-title,
    .map-form-title {
      font-size: .7em;
      font-family: var(--font);
        color: var(--black);

      small{
        font-size: .6em;
            color: var(--txtColor);

      }
    }

    .side-nav-title {
      display: flex;
      align-items: center;
      text-align: left;
      font-size: 1em;
      color: var(--titleColor);
    }
  }

  .map-menu-nav {
    z-index: 5;
    left: 0; //var(--sideNavWidth);
  }

  .sidenav-btn-wrapper {
    display: flex;
    padding: 10px;

    button {
      width: 100%;
      height: 50px;
    }
  }

  .parcel-menu-nav {
    right: 0px;

    .parcel-display-wrapper {
      display: flex;
      width: 100%;
      height: 250px;
      box-sizing: border-box;
      padding: 10px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: var(--defRadius);
      }
    }
    .multiple-parcel-display-wrapper {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      grid-gap: 5px;
      padding: 30px 10px;

      .lg-pixel-wrapper {
        display: flex;
        flex-flow: column;
        box-sizing: border-box;
        align-items: flex-start;
        grid-gap: 5px;

        .pixel-img-wrapper {
          height: 20px;
          width: 20px;
          background-color: var(--primaryColor);
        }

        .pixel-txt-wrapper {
          @include defTxt();
          font-size: 0.6em;
          text-align: center;
        }
      }
    }
  }

  // MENU FORM START -//

  .menu-index,
  input[type='checkbox'],
  input[type='radio'] {
    @include pointerItem();
    height: 25px;
    width: 25px;
    border-radius: 3px;
    margin: 0px !important;
    padding: 0px;
  }
  .menu-index {
    border: 1px solid var(--borderColor);

    &.sold {
      // background-color: var(--primaryColor);
      background-color: var(--blue);
    }

    &.available {
      background-color: var(--white);
      // background-color: var(--primaryColor);
    }
    &.accent {
      // background-color: var(--white);
      background-color: var(--accentColor);
    }
  }
  // MENU FORM END -//

  // MAIN BODY WRAPPER START -//
  .main-body-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 1;
    height: 100%; // calc(100vh - var(--mainNavHeight));
    width: 100%; //calc(100vw - var(--sideNavWidth));
    padding: 0px; //5vh 5vw;

    // overflow: auto;
    background-color: var(--bgColor);
  }
  // MAIN BODY WRAPPER END -//
}

// BASE PAGE END ------//
